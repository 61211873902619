
import { ref } from 'vue';
import { cloneDeep } from 'lodash';
import { IOwnerRelation } from '@/types/Interfaces';
import { client } from '@/types/Axios';

const fetchOwnerRelations = async (): Promise<IOwnerRelation[]> => {
  const {data} = await client.get('/owner_relations/')
  return data;
};

const createOwnerRelation = async (payload: IOwnerRelation) => {
  const {data} = await client.post('/owner_relations/', payload);
  return data;
}

const updateOwnerRelation = async (id: number, payload: IOwnerRelation) => {
  const {data} = await client.patch(`/owner_relations/${id}/`, payload);
  return data;
}

const deleteOwnerRelation = async (id: number) => {
  const {data} = await client.delete(`/owner_relations/${id}/`)
  return data;
}

export default function useOwnerRelation() {
  return {
    fetchOwnerRelations,
    createOwnerRelation,
    updateOwnerRelation,
    deleteOwnerRelation
  }
}
