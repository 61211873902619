
import { ref } from 'vue';
import { cloneDeep } from 'lodash';
import { IBenefit } from '@/types/Interfaces';
import {client} from '@/types/Axios'

const fetchBenefits = async (): Promise<IBenefit[]> => {
  const {data} = await client.get('/benefits/')
  return data;
};

const createBenefit = async (payload: IBenefit) => {
  const {data} = await client.post('/benefits/', payload);
  return data;
}

const updateBenefit = async (id: number, payload: IBenefit) => {
  const {data} = await client.patch(`/benefits/${id}/`, payload);
  return data;
}

const deleteBenefit = async (id: number) => {
  const {data} = await client.delete(`/benefits/${id}/`)
  return data;
}


export default function useBenefit() {
  return {
    fetchBenefits,
    createBenefit,
    updateBenefit,
    deleteBenefit
  }
}
