const validate = (data: any, validators: any, errors: any) => {
  console.log('validate')
  
  if (data.value == null) return;
  let hasError = false;
  for (const fName in validators) {
    let error = '';
    console.log(validators[fName])
    validators[fName].map((v: Function) => {
      if (data.value == null) return;
      if (error) return;
      const res = v(data.value[fName])
      if (res) error = res;
    })
    errors.value[fName] = error;
    if (error) hasError = true
  }
  return !hasError;
  
}

const required = (v: any) => {
  if (typeof v == 'number') return null;
  return v ? null : '必須項目です。' 
}
const isNumber = (v: string) => {
  if (isNaN(parseInt(v))) return '数値を入力して下さい。'
  return null
}
const isColor = (v: string) => {
  const s = new Option().style;
  s.color = v;
  console.log(s.color)
  return s.color != '' ? null : '有効な値を入力して下さい。';
}
const isEmail = (v: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(v).toLowerCase());
}
export {
  validate,
  required,
  isColor,
  isNumber,
  isEmail
}