<template>
  <div
    :style="{ '--color': status.color }"
    :class="{ 'has-child': hasChild, 'rounded-r-lg': !hasChild }"
    class="relative table-cell align-middle rounded-l-lg text-white px-3 text-xs"
  >
    
    {{ `${status.tag}. ${status.name}` }}
  </div>
</template>
<style scoped>
  div {
    word-break: break-all;
    height:50px;
    width: 100px;
    text-align:center;
    margin-right: 20px;
    background-color: var(--color);
  }
  /* div.step1 {
    background-color: tan;
  }
  div.step2 {
    background-color: #DB866F;
  }
  div.disabled {
    background-color: #7F7F7F;
  }
   */
  
  
  div.has-child:after{
    content:"";
    position:absolute;
    height:0;
    width:0;
    left:100%;
    top:0;
    border:25px solid transparent;
    border-left: 10px solid var(--color);
  }
  /* .step1:after {
    
  }
  .step2:after {
    border-left: 20px solid #DB866F;
  }
  
  .disabled:after {
    border-left: 20px solid #7F7F7F;
  } */
  
</style>
<script lang="ts">
import { computed, defineComponent, SetupContext, } from 'vue';
import FlowChip from './FlowChip.vue';

export default defineComponent({
  components: {
  },
  props: {
    status : {
      type: Object
    },
    hasChild: {
      type: Boolean
    }
  }
})
</script>