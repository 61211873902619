
import { computed, defineComponent, SetupContext, } from 'vue';
import FlowChip from './FlowChip.vue';

export default defineComponent({
  components: {
  },
  props: {
    status : {
      type: Object
    },
    hasChild: {
      type: Boolean
    }
  }
})
