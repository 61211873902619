<template>
  <div class="p-3 shadow-lg rounded bg-white px-6" v-if="dataReady">
    
    <form
      @submit.prevent="onSubmit"
    >
      <table id="info-table" class="w-full border-collapse">
        <tbody>
          <tr>
            <th>紹介されたお客様</th>
            <th>紹介したオーナー様</th>
          </tr>
          <tr>
            <td>
              <text-field
                label="名前"
                v-model="data.client_name"
                type="text"
                :error="errors.client_name"
              ></text-field>
            </td>
            <td>
              <text-field
                label="名前"
                :error="errors.owner_name"
                v-model="data.owner_name"
                type="text"
              ></text-field>
            </td>
          </tr>
          <tr>
            <td>
              <select-box
                label="検討内容"
                v-model="data.purpose_id"
                :options="purposeList.filter(d => d.display).map(p => ({ value: p.id, text: p.name }))"
              ></select-box>
              
            </td>
            <td>
              <select-box
                label="オーナ様種類"
                v-model="data.owner_type_id"
                :options="ownerTypeList.filter(d => d.display).map(ot => ({ value: ot.id, text: ot.name }))"
              ></select-box>
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <select-box
                label="オーナー様との関係"
                v-model="data.relation_id"
                :options="ownerRelationList.filter(d => d.display).map(p => ({ value: p.id, text: p.relation }))"
              ></select-box>
              <!-- <div class="text-left text-sm">
                オーナ様との関係
              </div>
              <div
                v-for="(or, i) in ownerRelationList.filter(o => o.display)"
                :key="i"
                :class="{ 'ml-2': i > 0 }"
              >
                <label :for="`relation-${i}`">
                  {{ or.relation }}
                </label>
                <input
                  :id="`relation-${i}`"
                  type="checkbox"
                  :checked="isOwnerRelationChecked(or.relation)"
                  @change="onCheckOwnerRelation(or, $event)"
                  class="ml-1"
                >
              </div> -->
            </td>
            <td>
              
            </td>
          </tr>
          <tr>
            <td>
              <text-field
              label="連携サービス1URL"
              type="text"
              v-model="data.sync_service1_url"
              ></text-field>
              <text-field
              label="連携サービス1テキスト"
              type="text"
              v-model="data.sync_service1_text"
              ></text-field>
            </td>
            <td>
              <text-field
              label="連携サービス4URL"
              type="text"
              v-model="data.sync_service4_url"
              ></text-field>
              <text-field
              label="連携サービス4テキスト"
              type="text"
              v-model="data.sync_service4_text"
              ></text-field>
            </td>
          </tr>
          <tr>
            <td>
              <text-field
              label="連携サービス2URL"
              type="text"
              v-model="data.sync_service2_url"
              ></text-field>
               <text-field
              label="連携サービス2テキスト"
              type="text"
              v-model="data.sync_service2_text"
              ></text-field>
            </td>
            <td>
              <text-field
              label="連携サービス5URL"
              type="text"
              v-model="data.sync_service5_url"
              ></text-field>
              <text-field
              label="連携サービス5テキスト"
              type="text"
              v-model="data.sync_service5_text"
              ></text-field>
            </td>
          </tr>
          <tr>
            <td>
              <text-field
              label="連携サービス3URL"
              type="text"
              v-model="data.sync_service3_url"
              ></text-field>
               <text-field
              label="連携サービス3テキスト"
              type="text"
              v-model="data.sync_service3_text"
              ></text-field>
            </td>
            <td>
              <text-field
              label="連携サービス6URL"
              type="text"
              v-model="data.sync_service6_url"
              ></text-field>
              <text-field
              label="連携サービス6テキスト"
              type="text"
              v-model="data.sync_service6_text"
              ></text-field>
            </td>
          </tr>
          <tr>
            <td>
              <select-box
              label="来場特典"
              v-model="data.client_visitor_benefit_id"
              :error="errors.client_visitor_benefit_id"
              :options="benefitList.filter(b => b.target == 'client_visitor').map(b => ({ value: b.id, text: b.benefit }))"
            ></select-box>
            </td>
            <td>
             <select-box
              label="来場特典"
              v-model="data.owner_visitor_benefit_id"
              :error="errors.owner_visitor_benefit_id"
              :options="benefitList.filter(b => b.target == 'owner_visitor').map(b => ({ value: b.id, text: b.benefit }))"
            ></select-box>
            </td>
          </tr>
          <tr>
            <td>
              <select-box
              label="成約特典"
              v-model="data.client_contract_benefit_id"
              :error="errors.client_contract_benefit_id"
              :options="benefitList.filter(b => b.target == 'client_contract').map(b => ({ value: b.id, text: b.benefit }))"
            ></select-box>
            </td>
            <td>
             <select-box
              label="成約特典"
              v-model="data.owner_contract_benefit_id"
              :error="errors.owner_contract_benefit_id"
              :options="benefitList.filter(b => b.target == 'owner_contract').map(b => ({ value: b.id, text: b.benefit }))"
            ></select-box>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="text-left">
              <div class="text-sm text-left">来場特典期限</div>
              <datepicker
                class="bg-white"
                :modelValue="new Date(data.visitor_benefit_expiration)"
                @update:modelValue="data.visitor_benefit_expiration = formatDate($event, 'YYYY-MM-DD')"
                
                :locale="ja"
                inputFormat="yyyy/MM/dd"
              />
              <div class="text-xs text-red-600">
                {{ errors.visitor_benefit_expiration }}
              </div>
              
            </td>
            <td class="text-left" colspan="1">
              <div class="text-sm">
                成約特典期限
              </div>
              <datepicker
                class="bg-white"
                :modelValue="new Date(data.contract_benefit_expiration)"
                @update:modelValue="data.contract_benefit_expiration = formatDate($event, 'YYYY-MM-DD')"
                :locale="ja"
                inputFormat="yyyy/MM/dd"
              />
              <div class="text-xs text-red-600">
                {{ errors.contract_benefit_expiration }}
              </div>
              <!-- <text-field
                label="成約特典期限"
                v-model="data.contract_benefit_expiration"
                type="text"
                :error="errors.contract_benefit_expiration"
              >
              </text-field> -->
            </td>
          </tr>
          <tr>
            
          </tr>
           <tr>
            <td colspan="1">
              
            </td>
          </tr>
        </tbody>
      </table>
    
    
      <hr v-if="id != 'new'" class="my-3">
      <div v-if="id != 'new'">
        <table class="">
          <tbody>
            <tr>
              <th>ステータス</th>
              <td v-if="data.history && data.history.length">
                {{ data.history[data.history.length - 1].after_status?.name }}
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="flex flex-auto"
          v-for="(d, i) in sortedStatus"
          :key="i"
        >
          <div
            class="flex "
          >
            <div
              v-for="(item, j) in d"
              :key="j"
              :class="{ 'ml-2': j > 0 }"
              class="mb-2"
                
            >
              <table>
                <tbody>
                  <tr>
                    <th class="flex ">
                      <div class="text-base">{{ item.tag }}.{{ item.name }}</div>
                      <div
                        v-if="item.require_approval "
                        class="bg-red-600 text-white text-xs rounded p-1"
                      >
                        承認まち
                      </div>
                      <div
                        v-if="data.history && data.history.length && data.history[data.history.length - 1].after_status?.name == item.name"
                        class="inline-block rounded bg-blue-500 text-white p-1 text-xs"
                      >
                        現在
                      </div>
                    </th>
                    <td class="flex items-center">
                      <datepicker
                        class="bg-white"
                        v-if="isParentDone(item.parent) && getNextStatus(item.tag).length"
                        :modelValue="getHistoryTransitionDate(item.id)"
                        @update:modelValue="onUpdateHistoryTransitionDate(item.id, $event)"
                        :locale="ja"
                        inputFormat="yyyy/MM/dd"
                      />
                      
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="pt-3"
          >
            
            <div
              v-for="(item, j) in d"
              :key="j"
              
              class=""
            >
              <!-- <div
                v-if="getChildren(proposalStatusList, item).length > 1"
              >
                <svg
                  v-for="(_, k) in getChildren(proposalStatusList, item)"
                  :style="`transform: rotate(${k * 20}deg)`"
                  :key="k"
                  class="w-6 h-6 mb-3"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  <path fill-rule="evenodd" d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
              </div> -->
            </div>
          </div>
          
        </div>
      </div>
      <hr class="my-3">
      <div class="text-left">
        <button
          :disabled="loading"
          type="submit"
          class="success w-32"
        >
          {{ data.id ? '更新' : '作成' }}
        </button>
      </div>
    </form>
    <frame-modal
      v-if="updatingHistory != null && getNextStatus(updatingHistory.before_status.tag).length"
      @close="updatingHistory = null"
    >
      <status-history-form
        :proposalId="data.id"
        :statusOptions="getNextStatus(updatingHistory.before_status.tag)"
        :currentStatusId="data.status?.id"
        @close="() => {}"
        @change="onChangeAfterStatus"
      >
      
      </status-history-form>
  
    </frame-modal>
    
  </div>
</template>
<style lang="scss" scoped>
  table {
    // tr {
    //   border-bottom: solid lightgray 1px;
    // }
    th, td {
      padding: 5px;
    }
    
  }
  #info-table {
    td:first-child {
      padding-right: 20px
    }
    td:last-child {
      border-left: solid lightgray 1px;
      padding-left: 20px
    }
  }
</style>
<script lang="ts">
import ja from 'date-fns/locale/ja'

import Datepicker from 'vue3-datepicker'
import { watch, computed, defineComponent, SetupContext, ref, onMounted, registerRuntimeCompiler } from 'vue';
import moment from 'moment';
import { IBenefit, IOwnerRelation, IOwnerType, IProposal, IProposalStatus, IProposalStatusHistory, IPurpose, } from '@/types/Interfaces';
import {cloneDeep} from 'lodash'
import useProposalStatus from '@/types/ProposalStatus';
import usePurpose from '@/types/Purpose';
import useOwnerRelation from '@/types/OwnerRelation';
import useOwnerType from '@/types/OwnerType';
import useProposal from '@/types/Proposal'
import { useRoute, useRouter } from 'vue-router';

import {formatDate} from '@/utils'
import useBenefit from '@/types/Benefit';
import StatusHistoryForm from '../StatusHistoryForm.vue'

import {
  required, validate  
} from '@/mixins/Validators';
import { enIN } from 'date-fns/locale';
import useFlashMessage from '@/types/FlashMessage';
import { lastDayOfDecade } from 'date-fns';

export default defineComponent({
  components: {
    Datepicker,
    StatusHistoryForm
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const data = ref<IProposal|null>(null);
    
    const {
      setMessage
    } = useFlashMessage()

    const {
      fetchBenefits  
    } = useBenefit();

    const {
      fetchPurposes
    } = usePurpose();

    const {
      fetchOwnerRelations
    } = useOwnerRelation();

    const {
      fetchOwnerTypes
    } = useOwnerType();

    const {
      fetchProposalStatus,
      sortStatus,
      getChildren
    } = useProposalStatus();

    
    const {
      getProposal,
      createProposal,
      updateProposal
    } = useProposal()

    const purposeList = ref<IPurpose[]>([]); //fetchPurposes();
    const ownerRelationList = ref<IOwnerRelation[]>([]);  // fetchOwnerRelations();
    const ownerTypeList = ref<IOwnerType[]>([]); // fetchOwnerTypes();
    const benefitList = ref<IBenefit[]>([]);
    const proposalStatusList = ref<IProposalStatus[]>([]); // fetchProposalStatus();
    const sortedStatus = ref<Array<any>>([]);
    
    const validators: { [key: string]: Array<Function> } = {
      client_name: [
        required
      ],
      client_visitor_benefit_id: [
        required
      ],
      client_contract_benefit_id: [
        required
      ],
      owner_name: [
        required
      ],
      owner_visitor_benefit_id: [
        required
      ],
      owner_contract_benefit_id: [
        required
      ],
      visitor_benefit_expiration: [
        required,
      ],
      contract_benefit_expiration: [
        required
      ]
    }
    const errors = ref<{ [key: string]: any }>({
      client_name: '',
      relation_id: '',
      purpose: '',
      client_visitor_benefit_id: '',
      client_contract_benefit_id: '',
      owner_name: '',
      owner_type_id: '',
      owner_visitor_benefit_id: '',
      owner_contract_benefit_id: '',
      visitor_benefit_expiration: '',
      contract_benefit_expiration: '',
    })
    
    
    // const statusHistory = ref<IProposalStatusHistory[]>([]);
    const dataReady = ref(false);
    const id = route.params.id.toString();
    // watch(
    //   () => data,
    //   () => { validate() },
    //   { deep: true }
    // )
    onMounted(() => {
      if (id == 'new') {
        // create newpan
         Promise.all([
          fetchBenefits(),
          fetchPurposes(),
          fetchOwnerRelations(),
          fetchOwnerTypes(),
          fetchProposalStatus(),
        ]).then(([
          benefits,
          purposes,
          ownerRelations,
          ownerTypes,
          proposalStatuses
        ]) => {
          dataReady.value = true;
          benefitList.value = benefits;
          purposeList.value = purposes;
          ownerRelationList.value = ownerRelations;
          ownerTypeList.value = ownerTypes;
          proposalStatusList.value = proposalStatuses;
          
          data.value = {
            client_name: '',
            owner_name: '',
            purpose_id: 0,
            relation_id: 0,
            linked_services: [],
            client_visitor_benefit_id: null,
            client_contract_benefit_id: null,
            owner_type_id: 0,
            owner_visitor_benefit_id: null,
            owner_contract_benefit_id: null,
            visitor_benefit_expiration: '',
            contract_benefit_expiration: '',
            status: null,
            history: [],
          };
          sortedStatus.value = sortStatus(proposalStatusList.value, data.value.purpose_id);
          
        })
      } else {
        Promise.all([
          getProposal(parseInt(id)),
          fetchBenefits(),
          fetchPurposes(),
          fetchOwnerRelations(),
          fetchOwnerTypes(),
          fetchProposalStatus(),
        ]).then(([
          proposal,
          benefits,
          purposes,
          ownerRelations,
          ownerTypes,
          proposalStatuses
        ]) => {
          dataReady.value = true;
          data.value= proposal;
          if (data.value == null) return;
          data.value.client_visitor_benefit_id = proposal.client_visitor_benefit.id;
          data.value.client_contract_benefit_id = proposal.client_contract_benefit.id;
          data.value.owner_visitor_benefit_id = proposal.owner_visitor_benefit.id;
          data.value.owner_contract_benefit_id = proposal.owner_contract_benefit.id;
          data.value.purpose_id = proposal.purpose.id;
          data.value.relation_id = proposal.relation?.id;
          data.value.owner_type_id = proposal.owner_type.id;

          benefitList.value = benefits;
          purposeList.value = purposes.filter(e => e.display);
          ownerRelationList.value = ownerRelations.filter(e => e.display);
          ownerTypeList.value = ownerTypes.filter(e => e.display);
          proposalStatusList.value = proposalStatuses;
          sortedStatus.value = sortStatus(proposalStatusList.value, data.value.purpose_id);

        })
      }
    })
  
    // const onCheckPurpose = (p: IPurpose, event: InputEvent) => {
      
    //   if (data.value == null) return;
    //   const {target} = event;
    //   if (!(target instanceof HTMLInputElement)) return;
    //   const value = target.value == 'on';
    //   const index = data.value.purposes.findIndex(e => e == p.name);
    //   if (index >= 0) {
    //     if (!value) {
    //       // remove 
    //       data.value.purposes.splice(index, 1)
    //     }
    //   } else {
    //     if (value) {
    //       data.value.purposes.push(p.name)
    //     }
    //   }
    // }

    // const onCheckOwnerRelation = (or: IOwnerRelation, event: InputEvent) => {
    //   if (data.value == null) return;
    //   const {target} = event;
    //   if (!(target instanceof HTMLInputElement)) return;
    //   const value = target.value == 'on';
    //   const index = data.value.relations.findIndex(e => e == or.relation);
    //   if (index >= 0) {
    //     if (!value) {
    //       // remove 
    //       data.value.relations.splice(index, 1)
    //     }
    //   } else {
    //     if (value) {
    //       data.value.relations.push(or.relation)
    //     }
    //   }
    // }
    
    // const onCheckOwnerType = (ot: IOwnerType, event: InputEvent) => {
    //   if (data.value == null) return;
    //   const {target} = event;
    //   if (!(target instanceof HTMLInputElement)) return;
    //   const value = target.value == 'on';
    //   const index = data.value.owner_types.findIndex(e => e == ot.name);
    //   if (index >= 0) {
    //     if (!value) {
    //       // remove 
    //       data.value.owner_types.splice(index, 1)
    //     }
    //   } else {
    //     if (value) {
    //       data.value.owner_types.push(ot.name)
    //     }
    //   }
    // }

    // const isPurposeChecked = (purposeName: string) => {
    //   return data.value?.purposes.find((p: string) => p == purposeName ) != null;
      
    // }

    // // const isOwnerRelationChecked = (relationName: string) => {
    //   return data.value?.relations.find((p: string) => p == relationName ) != null;
      
    // }
    
    // const isOwnerTypeChecked = (typeName: string) => {
    //   return data.value?.owner_types.find((p: string) => p == typeName ) != null;
      
    // }
    
    const checkParentIsCompleted = (parentTag: string) => {
      if (parentTag == null) return true;
      if (sortedStatus.value == null) return false;
      sortedStatus.value.map((layer: Array<any>) => {
        layer.map((s: any) => {
          if (s.tag == parentTag && s.completed_at) {
            // if done
            return true;
          }
        })
      })
      return false;
    }
    
    const loading = ref(false);
    const onSubmit = async () => {
      if (data.value == null) return;
      if (!validate(data, validators, errors)) {
        setMessage({ type: 'error', message: 'エラーを確認して下さい。' })
        return;
      }
      let r;
      try {
        loading.value = true;
        if (id == 'new') {
          // create
          console.log(data.value)
          r = await createProposal(data.value);
          
        } else {
          console.log(data.value)
          if (data.value.id == null) return;
          r = await updateProposal(data.value.id, data.value)
          console.log(sortedStatus.value)
        
        }
        const historyData = ref<IProposalStatusHistory[]>([])
        sortedStatus.value.map((layer: Array<any>) => {
          layer.map((s: any) => {
            if (s.started_at) {
              console.log('adding ' + s.name)
              historyData.value.push({
                proposal: data.value?.id || 0,
                before_status: s.parent,
                after_status: s.tag,
                transition_date: formatDate(s.started_at, 'YYYY-MM-DD')
              })
            }
          })
        })
        const m = data.value.id ? '更新しました' : '追加しました'
        setMessage({ type: 'success', message: m })
        router.push({ name: 'ProposalsList' })
        console.log(historyData.value)
      } catch (err) {
        console.error(err.response)
      }
      loading.value = false;
    }
    
    const benefitOptions = computed(() => {
      return benefitList.value.map(b => {
        return {
          value: b.id,
          text: b.benefit
        }
      })
    })

    const getHistoryTransitionDate = (statusId: number) => {
      if (data.value == null || data.value.history == null) return null;
      const h = data.value.history.find((h: IProposalStatusHistory) => h.before_status?.id == statusId);
      
      return h ? new Date(h.transition_date) : null;
    }

    const updatingHistory = ref<IProposalStatusHistory | null>(null);
    const getNextStatus = (tag: string) => {
      if (data.value == null) return;
      const next = cloneDeep(proposalStatusList.value.filter((d: any) => d.purpose == data.value?.purpose || d.purpose == data.value?.purpose_id)).filter((s: IProposalStatus) => s.parent == tag);
      return next;
    }
    const onUpdateHistoryTransitionDate = (statusId: number, date: Date) => {
      if (data.value == null) {
        return
      }
      if (data.value.history == null) {
        data.value.history = [];
      }
      const s = proposalStatusList.value.find(s => s.id == statusId);
      if (s == null) return;
      // check exists in data
      const history = data.value.history.find(h => h.before_status?.id == s.id)
      
      if(history) {
        updatingHistory.value = history;
      } else {
        // remove other option
        
        const newHistory: IProposalStatusHistory = {
          before_status_id: s.id || null,
          before_status: s,
          after_status_id: null,
          after_status: null,
          transition_date: moment(date.toISOString()).format('YYYY-MM-DD'),
          proposal: data.value.id || 0,
        }
        const otherOption = proposalStatusList.value.filter((ps: IProposalStatus) => (ps.parent == newHistory.before_status?.parent && ps.id != newHistory.before_status?.id) )
        console.log(otherOption)
        data.value.history.push(newHistory)
        updatingHistory.value = newHistory;
        
        console.log('new history')
      }
      
    }
    const isParentDone = (parentTag: string) => {
      if (parentTag == null || parentTag == '') return true;
      if (data.value == null || data.value.history == null) return false;
     
      const exist = data.value.history.find(h => h.before_status?.tag == parentTag)
      return exist != null && !exist.after_status?.require_approval
      
    }
    const onChangeAfterStatus = (event: any) => {
      console.log(event)
      if (updatingHistory.value == null) throw new Error('updatingHIstory null');
      // updatingHistory.value.after_status_id = event.after_status_id;
      updatingHistory.value.after_status = event.after_status;
      updatingHistory.value.after_status_id = event.after_status_id;
      console.log(event)
      const replacingIndex = data.value?.history?.findIndex(h => h.before_status?.id == updatingHistory.value?.before_status?.id)
      
      if (replacingIndex != null) {
      data.value?.history?.splice(replacingIndex, 1, updatingHistory.value);
      }
      updatingHistory.value = null;
      
    }
    
    return {
      id, 
      dataReady,
      loading,
      data,
      errors,
      benefitOptions,
      benefitList,
      purposeList,
      ownerRelationList,
      ownerTypeList,
      proposalStatusList,
      sortedStatus,
      checkParentIsCompleted,
      getChildren,
      ja,
      // onCheckOwnerRelation,
      // onCheckOwnerType,
      // isPurposeChecked,
      // isOwnerRelationChecked,
      // isOwnerTypeChecked,
      onSubmit,
      getHistoryTransitionDate,
      updatingHistory,
      onUpdateHistoryTransitionDate,
      isParentDone,
      getNextStatus,
      onChangeAfterStatus,
      formatDate,
    }
  }
})
</script>