
import { ref } from 'vue';
import { cloneDeep } from 'lodash';
import { IProposal, IProposalStatusHistory } from '@/types/Interfaces';
import { client } from './Axios';

const fetchProposalStatusHistory = async (proposalId = null): Promise<IProposalStatusHistory[]> => {
  const url = proposalId ? `/proposal_status_history/?proposal=${proposalId}` : '/proposal_status_history/';
  const {data} = await client.get(url)
  return data;
};

const createProposalStatusHistory = async (payload: IProposalStatusHistory): Promise<IProposalStatusHistory> => {
  const {data} = await client.post('/proposal_status_history/', payload);
  return data;
}
const updateProposalStatusHistory = async (id: number, payload: IProposalStatusHistory): Promise<IProposalStatusHistory> => {
  const {data} = await client.patch(`/proposal_status_history/${id}/`, payload);
  return data;
}

export default function useProposalStatusHistory() {
  return {
    fetchProposalStatusHistory,
    createProposalStatusHistory,
    updateProposalStatusHistory
  }
}
