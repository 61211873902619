<template>
  <div class="text-gray-900 inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
    <div class="bg-white px-6 pb-4 sm:p-6 sm:pb-4">
      <div
        class="pt-6"
      >
        <div>変更するステータスを選択してください。</div>
        <div class="mb-5 flex ">
          <template
            v-for="(op, i) in statusOptions"
            :key="i"
            
          >
            <div class="relative">
              
              <status-flow-chip
                class="cursor-pointer"
                
                :status="op"
                
                @click="onChangeStatus(op)"
              ></status-flow-chip>
                
              <div
                v-if="op.require_approval"
                style="top: -5px; left: -5px"
                class="absolute bg-red-600 text-white inline-block text-xs px-1 rounded"
              >
                承認
              </div>
            </div>
          </template>
          
        </div>
        <div v-if="dismissable">
          <button class="shadow " @click="onClose">
            閉じる
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { IProposalStatus, IProposalStatusHistory } from '@/types/Interfaces';
import useProposalStatus from '@/types/ProposalStatus';
import { computed, defineComponent, onMounted, PropType, ref, SetupContext, } from 'vue';
import StatusFlowChip from './StatusFlowChip.vue';

import moment from 'moment';
import useProposalStatusHistory from '@/types/ProposalStatusHistory';


export default defineComponent({
  components: {
    StatusFlowChip,
    
  },
  props: {
    proposalId: Number,
    currentStatusId: Number,
    statusOptions: Object as () => IProposalStatus[],
    dismissable: {
      type: Boolean,
      default: true
    },
  },
  emits: [
    'change',
    'close'
  ],
  setup(props: any, context: SetupContext) {
    
    const {
      createProposalStatusHistory
    } = useProposalStatusHistory();
    
    const loading = ref(false);
    const onClose = () => {
      context.emit('close');  
    };
    // const onUpdate = () => {
    //   // do some async work
    //   context.emit('update', data.value);
    // };

    const onChangeStatus = async (op: IProposalStatus) => {
      console.log(op)
      // interface IPayload{
      //   after_status: Number;
      //   transition_date: String
      // }
      if (loading.value) return;
      if (op.id == null) return;
      const payload: any = {
        proposal: props.proposalId,
        before_status_id: props.currentStatusId,
        after_status_id: op.id,
        after_status: op,
        transition_date: moment().format('YYYY-MM-DD')
      }
      // console.log(payload)
      loading.value = true;
      
      
      context.emit('change', payload)
    }

    
    return {
      loading,
      onClose,
      onChangeStatus
    }
  }
})
</script>
