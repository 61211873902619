
import { ref } from 'vue';
import { cloneDeep } from 'lodash';
import { IOwnerType } from '@/types/Interfaces';
import {client} from '@/types/Axios'

const fetchOwnerTypes = async (): Promise<IOwnerType[]> => {
  const {data} = await client.get('/owner_types/')
  return data;
};

const createOwnerType = async (payload: IOwnerType) => {
  const {data} = await client.post('/owner_types/', payload);
  return data;
}

const updateOwnerType = async (id: number, payload: IOwnerType) => {
  const {data} = await client.patch(`/owner_types/${id}/`, payload);
  return data;
}


const deleteOwnerType = async (id: number) => {
  const {data} = await client.delete(`/owner_types/${id}/`)
  return data;
}

export default function useOwnerType() {
  return {
    fetchOwnerTypes,
    createOwnerType,
    updateOwnerType,
    deleteOwnerType
  }
}
