
import ja from 'date-fns/locale/ja'

import Datepicker from 'vue3-datepicker'
import { watch, computed, defineComponent, SetupContext, ref, onMounted, registerRuntimeCompiler } from 'vue';
import moment from 'moment';
import { IBenefit, IOwnerRelation, IOwnerType, IProposal, IProposalStatus, IProposalStatusHistory, IPurpose, } from '@/types/Interfaces';
import {cloneDeep} from 'lodash'
import useProposalStatus from '@/types/ProposalStatus';
import usePurpose from '@/types/Purpose';
import useOwnerRelation from '@/types/OwnerRelation';
import useOwnerType from '@/types/OwnerType';
import useProposal from '@/types/Proposal'
import { useRoute, useRouter } from 'vue-router';

import {formatDate} from '@/utils'
import useBenefit from '@/types/Benefit';
import StatusHistoryForm from '../StatusHistoryForm.vue'

import {
  required, validate  
} from '@/mixins/Validators';
import { enIN } from 'date-fns/locale';
import useFlashMessage from '@/types/FlashMessage';
import { lastDayOfDecade } from 'date-fns';

export default defineComponent({
  components: {
    Datepicker,
    StatusHistoryForm
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const data = ref<IProposal|null>(null);
    
    const {
      setMessage
    } = useFlashMessage()

    const {
      fetchBenefits  
    } = useBenefit();

    const {
      fetchPurposes
    } = usePurpose();

    const {
      fetchOwnerRelations
    } = useOwnerRelation();

    const {
      fetchOwnerTypes
    } = useOwnerType();

    const {
      fetchProposalStatus,
      sortStatus,
      getChildren
    } = useProposalStatus();

    
    const {
      getProposal,
      createProposal,
      updateProposal
    } = useProposal()

    const purposeList = ref<IPurpose[]>([]); //fetchPurposes();
    const ownerRelationList = ref<IOwnerRelation[]>([]);  // fetchOwnerRelations();
    const ownerTypeList = ref<IOwnerType[]>([]); // fetchOwnerTypes();
    const benefitList = ref<IBenefit[]>([]);
    const proposalStatusList = ref<IProposalStatus[]>([]); // fetchProposalStatus();
    const sortedStatus = ref<Array<any>>([]);
    
    const validators: { [key: string]: Array<Function> } = {
      client_name: [
        required
      ],
      client_visitor_benefit_id: [
        required
      ],
      client_contract_benefit_id: [
        required
      ],
      owner_name: [
        required
      ],
      owner_visitor_benefit_id: [
        required
      ],
      owner_contract_benefit_id: [
        required
      ],
      visitor_benefit_expiration: [
        required,
      ],
      contract_benefit_expiration: [
        required
      ]
    }
    const errors = ref<{ [key: string]: any }>({
      client_name: '',
      relation_id: '',
      purpose: '',
      client_visitor_benefit_id: '',
      client_contract_benefit_id: '',
      owner_name: '',
      owner_type_id: '',
      owner_visitor_benefit_id: '',
      owner_contract_benefit_id: '',
      visitor_benefit_expiration: '',
      contract_benefit_expiration: '',
    })
    
    
    // const statusHistory = ref<IProposalStatusHistory[]>([]);
    const dataReady = ref(false);
    const id = route.params.id.toString();
    // watch(
    //   () => data,
    //   () => { validate() },
    //   { deep: true }
    // )
    onMounted(() => {
      if (id == 'new') {
        // create newpan
         Promise.all([
          fetchBenefits(),
          fetchPurposes(),
          fetchOwnerRelations(),
          fetchOwnerTypes(),
          fetchProposalStatus(),
        ]).then(([
          benefits,
          purposes,
          ownerRelations,
          ownerTypes,
          proposalStatuses
        ]) => {
          dataReady.value = true;
          benefitList.value = benefits;
          purposeList.value = purposes;
          ownerRelationList.value = ownerRelations;
          ownerTypeList.value = ownerTypes;
          proposalStatusList.value = proposalStatuses;
          
          data.value = {
            client_name: '',
            owner_name: '',
            purpose_id: 0,
            relation_id: 0,
            linked_services: [],
            client_visitor_benefit_id: null,
            client_contract_benefit_id: null,
            owner_type_id: 0,
            owner_visitor_benefit_id: null,
            owner_contract_benefit_id: null,
            visitor_benefit_expiration: '',
            contract_benefit_expiration: '',
            status: null,
            history: [],
          };
          sortedStatus.value = sortStatus(proposalStatusList.value, data.value.purpose_id);
          
        })
      } else {
        Promise.all([
          getProposal(parseInt(id)),
          fetchBenefits(),
          fetchPurposes(),
          fetchOwnerRelations(),
          fetchOwnerTypes(),
          fetchProposalStatus(),
        ]).then(([
          proposal,
          benefits,
          purposes,
          ownerRelations,
          ownerTypes,
          proposalStatuses
        ]) => {
          dataReady.value = true;
          data.value= proposal;
          if (data.value == null) return;
          data.value.client_visitor_benefit_id = proposal.client_visitor_benefit.id;
          data.value.client_contract_benefit_id = proposal.client_contract_benefit.id;
          data.value.owner_visitor_benefit_id = proposal.owner_visitor_benefit.id;
          data.value.owner_contract_benefit_id = proposal.owner_contract_benefit.id;
          data.value.purpose_id = proposal.purpose.id;
          data.value.relation_id = proposal.relation?.id;
          data.value.owner_type_id = proposal.owner_type.id;

          benefitList.value = benefits;
          purposeList.value = purposes.filter(e => e.display);
          ownerRelationList.value = ownerRelations.filter(e => e.display);
          ownerTypeList.value = ownerTypes.filter(e => e.display);
          proposalStatusList.value = proposalStatuses;
          sortedStatus.value = sortStatus(proposalStatusList.value, data.value.purpose_id);

        })
      }
    })
  
    // const onCheckPurpose = (p: IPurpose, event: InputEvent) => {
      
    //   if (data.value == null) return;
    //   const {target} = event;
    //   if (!(target instanceof HTMLInputElement)) return;
    //   const value = target.value == 'on';
    //   const index = data.value.purposes.findIndex(e => e == p.name);
    //   if (index >= 0) {
    //     if (!value) {
    //       // remove 
    //       data.value.purposes.splice(index, 1)
    //     }
    //   } else {
    //     if (value) {
    //       data.value.purposes.push(p.name)
    //     }
    //   }
    // }

    // const onCheckOwnerRelation = (or: IOwnerRelation, event: InputEvent) => {
    //   if (data.value == null) return;
    //   const {target} = event;
    //   if (!(target instanceof HTMLInputElement)) return;
    //   const value = target.value == 'on';
    //   const index = data.value.relations.findIndex(e => e == or.relation);
    //   if (index >= 0) {
    //     if (!value) {
    //       // remove 
    //       data.value.relations.splice(index, 1)
    //     }
    //   } else {
    //     if (value) {
    //       data.value.relations.push(or.relation)
    //     }
    //   }
    // }
    
    // const onCheckOwnerType = (ot: IOwnerType, event: InputEvent) => {
    //   if (data.value == null) return;
    //   const {target} = event;
    //   if (!(target instanceof HTMLInputElement)) return;
    //   const value = target.value == 'on';
    //   const index = data.value.owner_types.findIndex(e => e == ot.name);
    //   if (index >= 0) {
    //     if (!value) {
    //       // remove 
    //       data.value.owner_types.splice(index, 1)
    //     }
    //   } else {
    //     if (value) {
    //       data.value.owner_types.push(ot.name)
    //     }
    //   }
    // }

    // const isPurposeChecked = (purposeName: string) => {
    //   return data.value?.purposes.find((p: string) => p == purposeName ) != null;
      
    // }

    // // const isOwnerRelationChecked = (relationName: string) => {
    //   return data.value?.relations.find((p: string) => p == relationName ) != null;
      
    // }
    
    // const isOwnerTypeChecked = (typeName: string) => {
    //   return data.value?.owner_types.find((p: string) => p == typeName ) != null;
      
    // }
    
    const checkParentIsCompleted = (parentTag: string) => {
      if (parentTag == null) return true;
      if (sortedStatus.value == null) return false;
      sortedStatus.value.map((layer: Array<any>) => {
        layer.map((s: any) => {
          if (s.tag == parentTag && s.completed_at) {
            // if done
            return true;
          }
        })
      })
      return false;
    }
    
    const loading = ref(false);
    const onSubmit = async () => {
      if (data.value == null) return;
      if (!validate(data, validators, errors)) {
        setMessage({ type: 'error', message: 'エラーを確認して下さい。' })
        return;
      }
      let r;
      try {
        loading.value = true;
        if (id == 'new') {
          // create
          console.log(data.value)
          r = await createProposal(data.value);
          
        } else {
          console.log(data.value)
          if (data.value.id == null) return;
          r = await updateProposal(data.value.id, data.value)
          console.log(sortedStatus.value)
        
        }
        const historyData = ref<IProposalStatusHistory[]>([])
        sortedStatus.value.map((layer: Array<any>) => {
          layer.map((s: any) => {
            if (s.started_at) {
              console.log('adding ' + s.name)
              historyData.value.push({
                proposal: data.value?.id || 0,
                before_status: s.parent,
                after_status: s.tag,
                transition_date: formatDate(s.started_at, 'YYYY-MM-DD')
              })
            }
          })
        })
        const m = data.value.id ? '更新しました' : '追加しました'
        setMessage({ type: 'success', message: m })
        router.push({ name: 'ProposalsList' })
        console.log(historyData.value)
      } catch (err) {
        console.error(err.response)
      }
      loading.value = false;
    }
    
    const benefitOptions = computed(() => {
      return benefitList.value.map(b => {
        return {
          value: b.id,
          text: b.benefit
        }
      })
    })

    const getHistoryTransitionDate = (statusId: number) => {
      if (data.value == null || data.value.history == null) return null;
      const h = data.value.history.find((h: IProposalStatusHistory) => h.before_status?.id == statusId);
      
      return h ? new Date(h.transition_date) : null;
    }

    const updatingHistory = ref<IProposalStatusHistory | null>(null);
    const getNextStatus = (tag: string) => {
      if (data.value == null) return;
      const next = cloneDeep(proposalStatusList.value.filter((d: any) => d.purpose == data.value?.purpose || d.purpose == data.value?.purpose_id)).filter((s: IProposalStatus) => s.parent == tag);
      return next;
    }
    const onUpdateHistoryTransitionDate = (statusId: number, date: Date) => {
      if (data.value == null) {
        return
      }
      if (data.value.history == null) {
        data.value.history = [];
      }
      const s = proposalStatusList.value.find(s => s.id == statusId);
      if (s == null) return;
      // check exists in data
      const history = data.value.history.find(h => h.before_status?.id == s.id)
      
      if(history) {
        updatingHistory.value = history;
      } else {
        // remove other option
        
        const newHistory: IProposalStatusHistory = {
          before_status_id: s.id || null,
          before_status: s,
          after_status_id: null,
          after_status: null,
          transition_date: moment(date.toISOString()).format('YYYY-MM-DD'),
          proposal: data.value.id || 0,
        }
        const otherOption = proposalStatusList.value.filter((ps: IProposalStatus) => (ps.parent == newHistory.before_status?.parent && ps.id != newHistory.before_status?.id) )
        console.log(otherOption)
        data.value.history.push(newHistory)
        updatingHistory.value = newHistory;
        
        console.log('new history')
      }
      
    }
    const isParentDone = (parentTag: string) => {
      if (parentTag == null || parentTag == '') return true;
      if (data.value == null || data.value.history == null) return false;
     
      const exist = data.value.history.find(h => h.before_status?.tag == parentTag)
      return exist != null && !exist.after_status?.require_approval
      
    }
    const onChangeAfterStatus = (event: any) => {
      console.log(event)
      if (updatingHistory.value == null) throw new Error('updatingHIstory null');
      // updatingHistory.value.after_status_id = event.after_status_id;
      updatingHistory.value.after_status = event.after_status;
      updatingHistory.value.after_status_id = event.after_status_id;
      console.log(event)
      const replacingIndex = data.value?.history?.findIndex(h => h.before_status?.id == updatingHistory.value?.before_status?.id)
      
      if (replacingIndex != null) {
      data.value?.history?.splice(replacingIndex, 1, updatingHistory.value);
      }
      updatingHistory.value = null;
      
    }
    
    return {
      id, 
      dataReady,
      loading,
      data,
      errors,
      benefitOptions,
      benefitList,
      purposeList,
      ownerRelationList,
      ownerTypeList,
      proposalStatusList,
      sortedStatus,
      checkParentIsCompleted,
      getChildren,
      ja,
      // onCheckOwnerRelation,
      // onCheckOwnerType,
      // isPurposeChecked,
      // isOwnerRelationChecked,
      // isOwnerTypeChecked,
      onSubmit,
      getHistoryTransitionDate,
      updatingHistory,
      onUpdateHistoryTransitionDate,
      isParentDone,
      getNextStatus,
      onChangeAfterStatus,
      formatDate,
    }
  }
})
