
import { IProposalStatus, IProposalStatusHistory } from '@/types/Interfaces';
import useProposalStatus from '@/types/ProposalStatus';
import { computed, defineComponent, onMounted, PropType, ref, SetupContext, } from 'vue';
import StatusFlowChip from './StatusFlowChip.vue';

import moment from 'moment';
import useProposalStatusHistory from '@/types/ProposalStatusHistory';


export default defineComponent({
  components: {
    StatusFlowChip,
    
  },
  props: {
    proposalId: Number,
    currentStatusId: Number,
    statusOptions: Object as () => IProposalStatus[],
    dismissable: {
      type: Boolean,
      default: true
    },
  },
  emits: [
    'change',
    'close'
  ],
  setup(props: any, context: SetupContext) {
    
    const {
      createProposalStatusHistory
    } = useProposalStatusHistory();
    
    const loading = ref(false);
    const onClose = () => {
      context.emit('close');  
    };
    // const onUpdate = () => {
    //   // do some async work
    //   context.emit('update', data.value);
    // };

    const onChangeStatus = async (op: IProposalStatus) => {
      console.log(op)
      // interface IPayload{
      //   after_status: Number;
      //   transition_date: String
      // }
      if (loading.value) return;
      if (op.id == null) return;
      const payload: any = {
        proposal: props.proposalId,
        before_status_id: props.currentStatusId,
        after_status_id: op.id,
        after_status: op,
        transition_date: moment().format('YYYY-MM-DD')
      }
      // console.log(payload)
      loading.value = true;
      
      
      context.emit('change', payload)
    }

    
    return {
      loading,
      onClose,
      onChangeStatus
    }
  }
})
