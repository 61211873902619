
import { ref } from 'vue';
import { IProposal } from '@/types/Interfaces';
import { client } from './Axios';

const fetchProposals = async (query: any = null): Promise<IProposal[]> => {
  const {data} = await client.get('/proposals/', { params: query })
  return data;
};

const getProposal = async (id: number) => {
  const {data} = await client.get(`/proposals/${id}/`)
  console.log(data)
  return data;
}

const createProposal = async (payload: IProposal) => {
  const {data} = await client.post('/proposals/', payload)
  return data;  
}

const updateProposal = async (id: number, payload: IProposal) => {
  const {data} = await client.patch(`/proposals/${id}/`, payload)
  return data;
}

const deleteProposal = async (id: number) => {
  const {data} = await client.delete(`/proposals/${id}/`)
  return data;
}
export default function useProposal() {
  return {
    fetchProposals,
    getProposal,
    createProposal,
    updateProposal,
    deleteProposal
  }
}
